import React, { useEffect } from 'react'

export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);
    return (
        <div>
            <div className="Main">
                <div className="TermsConditionsArea">
                    <div className="container">
                        <div className="section-title" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
                            <h2>Privacy Policy</h2>
                        </div>
                        <div className="TermsContent" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
                            <p>
                                Welcome to Zula Technologies Limited (“Zula,” “we,” “our,” or “us”).
                                By accessing or using our Services—including our website (
                                <a
                                    href="https://zulachat.com/"
                                    target="_blank"
                                    style={{ color: "#E94057", textDecoration: "underline" }}
                                >
                                    https://zulachat.com
                                </a>
                                )—you agree to this Privacy Policy (“Policy”) and accept the practices
                                we outline here for collecting, using, and managing your information.
                                If you do not agree, please do not use our Services.
                            </p>
                            <h4>Information We Collect</h4>
                            <p>
                                To offer you the best experience possible, we collect the following
                                types of Personal Information:
                            </p>
                            <ul>
                                <li>
                                    Information You Provide: When you interact with us, you may be
                                    required to provide information, including:
                                </li>
                                <li>Name</li>
                                <li>Email address</li>
                                <li>Social media platform account for review</li>
                                <li>Number of followers</li>
                                <li>Social media username</li>
                            </ul>
                            <p>
                                This information allows us to evaluate accounts and enhance your
                                experience with Zula.
                            </p>
                            <ul>
                                <li>
                                    Automated Information: We may automatically gather details such as
                                    your IP address, device information, and browsing behavior. Cookies
                                    and similar technologies enable us to streamline and personalize
                                    your experience. While you can control cookies via browser settings,
                                    doing so may reduce the quality of your experience with our
                                    Services.
                                </li>
                            </ul>
                        </div>
                        <div className="TermsContent">
                            <h4>Sensitive Information</h4>
                            <p>
                                We don’t collect sensitive information like health data, political
                                beliefs, or sexual orientation unless you explicitly provide it. If
                                collected, we retain it only as long as needed.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>External Links and Third-Party Sites</h4>
                            <p>
                                Our Services may link to external sites. Zula has no control over
                                third-party content or policies. We recommend you review their privacy
                                policies to understand their practices.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>Using Your Information</h4>
                            <p>Your information enables us to:</p>
                            <ul>
                                <li>
                                    Process and Evaluate Submissions: Information such as social media
                                    profiles and follower counts is critical for account reviews.
                                </li>
                                <li>
                                    Improve and Optimize Services: We analyze site traffic and
                                    interactions to enhance our offerings and streamline user
                                    experiences.
                                </li>
                                <li>
                                    Communicate with You: Zula may contact you with information relevant
                                    to your submission or general inquiries. By submitting your email,
                                    you agree to receive communications, which you can opt out of at any
                                    time.
                                </li>
                            </ul>
                        </div>
                        <div className="TermsContent">
                            <h4>Privacy and Minors</h4>
                            <p>
                                Our Services are not for individuals under 18 years of age, and we do
                                not knowingly collect information from minors. If you are under 18,
                                you may not use our Services. Should we discover that we hold
                                information on minors, we will promptly delete it.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>Retention and Deletion of Information</h4>
                            <p>
                                Zula retains your information as long as it is useful for providing
                                Services, complying with legal requirements, or protecting our
                                interests. If you close your account or submit a deletion request, we
                                may delete your information within a reasonable period but reserve the
                                right to retain information as necessary for legal or business
                                purposes.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>Sharing Information</h4>
                            <p>We may share your information with:</p>
                            <ul>
                                <li>
                                    Service Providers: Third-party providers supporting our operations
                                    (e.g., technical support, analytics) will only use your information
                                    for specific tasks on our behalf.
                                </li>
                                <li>
                                    De-identified Information: Zula may use anonymized or aggregated
                                    data for analysis, business, or marketing purposes.
                                </li>
                            </ul>
                        </div>
                        <div className="TermsContent">
                            <h4>Security Measures</h4>
                            <p>
                                While we use industry-standard security measures, no online data
                                transmission is entirely secure. You acknowledge and accept these
                                risks when providing information to us.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>Your Rights and Choices</h4>
                            <p>
                                You have the following rights regarding your Personal Information:
                            </p>
                            <ul>
                                <li>Access and Download: Request access to your information.</li>
                                <li>
                                    Update or Delete: Update, correct, or delete your information
                                    through account settings or by contacting us. Note that deleting
                                    your information may affect the Services we can provide.
                                </li>
                                <li>
                                    Restrict Processing: Request limits on our use of your data,
                                    acknowledging that this may impact your experience with our
                                    Services.
                                </li>
                            </ul>
                        </div>
                        <div className="TermsContent">
                            <h4>Data Transfers</h4>
                            <p>
                                Your data may be processed and stored outside your jurisdiction. We
                                comply with applicable legal standards for cross-border data
                                transfers, including the GDPR for our EU/UK operations.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>Legal Compliance</h4>
                            <p>
                                We comply with the General Data Protection Regulation (GDPR) for our
                                EU and UK Services and other applicable laws.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>Policy Modifications</h4>
                            <p>
                                We may update this Policy to reflect changes in our practices. Updated
                                versions will be posted on our website, and your continued use of our
                                Services indicates acceptance of the new terms.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>Contact Us</h4>
                            <p>
                                Questions or requests? Contact our Privacy/Data Protection Officer:
                            </p>
                            <p>
                                Privacy/Data Protection Officer <br />
                                Zula Technologies Limited <br />
                                Suite 5, 39 Irish Town, Gibraltar, GX11 1AA <br />
                                Email: <a href="mailto:info@zulachat.com" style={{color:"#E94057", textDecoration:"underline"}}>info@zulachat.com</a> 
                                {/* zulachat1@gmail.com */}
                            </p>
                            <p>
                                Please allow 48 to 72 hours for responses, with additional time for
                                complex requests.
                            </p>
                            <p>This Policy is effective as of October 25th.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
