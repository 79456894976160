import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';

export const HomePage = (props) => {
    const [emailModal, setEmailModal] = React.useState(false);
    const [thankYouModal, setThankYouModal] = React.useState(false);
    const { whyZulaChatRef } = props
    const handleThankYouOpen = () => {
        setThankYouModal(true);
    }
    const handleThankYouClose = () => {
        console.log("hello anas");
        setThankYouModal(false);
    }
    const handleEmailModalOpen = () => {
        setEmailModal(true);
    }
    const handleEmailModalClose = () => {
        setEmailModal(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    const location = useLocation();

    useEffect(() => {
        if (location.state?.section) {
            document.getElementById(location.state.section)?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    // const whyZulaChatRef = useRef(null);

    // const handleScrollToWhyZulaChat = () => {
    //     if (whyZulaChatRef.current) {
    //         whyZulaChatRef.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    // };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        socialAccountType: '',
        userName: '',
        numberOfFollowers: '',
        termsAccepted: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // Validate individual fields
    const validateFields = () => {
        if (!formData.name) {
            toast.error('Name is required.', { autoClose: 3000 });
            return false;
        }
        if (!formData.email) {
            toast.error('Email is required.', { autoClose: 3000 });
            return false;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            toast.error('Invalid email format.', { autoClose: 3000 });
            return false;
        }
        if (!formData.socialAccountType) {
            toast.error('Please select a platform.', { autoClose: 3000 });
            return false;
        }
        if (!formData.userName) {
            toast.error('Username is required.', { autoClose: 3000 });
            return false;
        }
        if (!formData.numberOfFollowers) {
            toast.error('Number of followers is required.', { autoClose: 3000 });
            return false;
        }
        if (isNaN(formData.numberOfFollowers)) {
            toast.error('Followers must be a valid number.', { autoClose: 3000 });
            return false;
        }
        if (!formData.termsAccepted) {
            toast.error('You must accept the terms and conditions.', { autoClose: 3000 });
            return false;
        }
        return true;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        console.log("hello log");
        e.preventDefault();
        if (!validateFields()) return;
        try {
            const response = await axios.post(
                'https://zulachat.com:5002/api/v1/mail/send-query-mail',
                formData
            );
            if (response.status === 200) {
                toast.success('Your query has been submitted successfully!', { autoClose: 3000 });
                setFormData({
                    name: '',
                    email: '',
                    socialAccountType: '',
                    userName: '',
                    numberOfFollowers: '',
                    termsAccepted: false,
                }); // Reset form state
                handleEmailModalClose();
                setThankYouModal(true) // Close the modal
            } else {
                toast.error('Failed to submit your query. Please try again.', { autoClose: 3000 });
            }
        } catch (error) {
            console.error(error);
            toast.error('Something went wrong! Please try again later.', { autoClose: 3000 });
        }
    };


    return (
        <>
            <div>
                <ToastContainer />

                {/*  */}
                {/* <header>
          <div class="top-container">
          <div class="row">
              <div class="col-3">
                      <img src="images/footer/Group 336.png">
              </div>
              <div class="col-6">
                  <ul class="nav">
                      <li>About Zulachat</li>
                      <li>Privacy Policy</li>
                      <li>Contact Us</li>
                  </ul>
              </div>
              <div class="col-3">
                  <ul class="playstore">
                      <li><img src="images/App Store.png"></li>
                      <li><img src="images/Play Store.png"></li>
                  </ul>
              </div>
          </div>
      </div>
      </header> */}
                <section className="hero-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="hero-left" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1200">
                                    <h1 data-aos="fade-up">
                                        Join an Exclusive Network of Influencers on{" "}
                                        <span className="highlight">Zulachat!</span>
                                    </h1>
                                    <p className="lead">
                                        Whether you're a big name, a small creator, or an up-and-coming
                                        influencer, it’s worth a try to apply to Zulachat. This is the
                                        platform to amplify your reach, engage with your audience, and
                                        monetize your following. Ready to turn your influence into
                                        income?
                                    </p>
                                    {/* <a href="#" class="btn btn-danger btn-lg">Apply to Join Zulachat Now <i
                                  class="fa-solid fa-arrow-right"></i></a> */}
                                    <button data-aos="fade-up"
                                        type="submit"
                                        className="btn btn-danger"
                                        onClick={() => setEmailModal(true)}>

                                        Apply to Join Zulachat Now &nbsp;&nbsp;
                                        <i className="fa-solid fa-arrow-right" />
                                    </button>
                                    <h2 className="launching-year">
                                        Launching in <span className="highlight">2025</span>
                                    </h2>
                                    <div className="download-section">
                                        <ul className="playstore">
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <img src={require('../images/App Store.png')} />

                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);">
                                                    <img src={require('../images/Play Store.png')} />

                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="app-mockup" data-aos="zoom-out" data-aos-delay="100" data-aos-duration="1200">
                                    <img src={require('../images/hero-right.png')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="OverlayImg">
                        <img src={require('../images/overlay.png')} />

                    </span>
                </section>
                {/* </div> */}
                <section className="why-zulachat text-center" id="sectionName"
                // ref={whyZulaChatRef}
                >
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="800">
                            <h2>
                                Why <span className="highlight">Zulachat?</span>
                            </h2>
                            <p className="lead mt-4">
                                Are you tired of making cents on the dollar for thousands of views on
                                other social media platforms? If so, you’re in luck—Zulachat is
                                designed to empower creators like you with real earning potential and
                                meaningful connections.
                            </p>
                        </div>
                        <div className="row text-start mt-5">
                            {/* Card 1 */}
                            <div className="col-lg-3 col-md-6 mb-4">
                                <div className="card shadow-sm h-100" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1200">
                                    <div className="card-body">
                                        <div className="icon mb-3">
                                            {/* <img
                                                src="images/Icon.png"
                                                alt="Seamless Interaction"
                                                className="img-fluid"
                                            /> */}
                                            <img src={require('../images/Icon.png')} />
                                        </div>
                                        <h5 className="card-title">
                                            Seamless <br /> Interaction
                                        </h5>
                                        <p className="card-text">
                                            Connect effortlessly with your audience through our clean,
                                            user-friendly interface, crafted to help you communicate and
                                            engage with followers smoothly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* Card 2 */}
                            <div className="col-lg-3 col-md-6 mb-4">
                                <div className="card shadow-sm h-100" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1200">
                                    <div className="card-body">
                                        <div className="icon mb-3">
                                            <img className="img-fluid" src={require('../images/Icon (1).png')} />
                                        </div>
                                        <h5 className="card-title">
                                            Direct Monetization <br /> Opportunities
                                        </h5>
                                        <p className="card-text">
                                            Break down the barriers between you and your followers.
                                            Zulachat enables you to monetize directly, giving you the
                                            freedom to earn what you deserve for the value you bring.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* Card 3 */}
                            <div className="col-lg-3 col-md-6 mb-4">
                                <div className="card shadow-sm h-100" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1200">
                                    <div className="card-body">
                                        <div className="icon mb-3">
                                            <img className="img-fluid" src={require('../images/Icon (2).png')} />
                                        </div>
                                        <h5 className="card-title">
                                            Huge Growth <br /> Potential
                                        </h5>
                                        <p className="card-text">
                                            Join a platform focused on influencer growth. Reach new
                                            followers, expand your influence, and unlock tools that help
                                            you scale quickly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* Card 4 */}
                            <div className="col-lg-3 col-md-6 mb-4">
                                <div className="card shadow-sm h-100" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1200">
                                    <div className="card-body">
                                        <div className="icon mb-3">
                                            <img
                                                src={require('../images/Icon (3).png')}
                                                alt="Engaging Posts"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <h5 className="card-title">
                                            Engaging Posts and <br /> Exclusive Content
                                        </h5>
                                        <p className="card-text">
                                            Share unique content that stands out. Our platform supports
                                            high-quality posts, allowing you to deliver exclusive updates
                                            and build loyalty with your followers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="share-memories-section">
                    <div className="container">
                        <div className="row">
                            {/* Left Side: Phone and Stories */}
                            <div className="col-lg-5">
                                <div className="phone-image" data-aos="zoom-out" data-aos-delay="100" data-aos-duration="1200">
                                    <img
                                        src={require('../images/faveourite.png')}
                                        className="img-fluid"
                                        alt="Phone Image"
                                    />
                                </div>
                            </div>
                            {/* Right Side: Text Content */}
                            <div className="col-lg-7">
                                <div className="ShareRight">
                                    <h2 data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                        Share Your Favourite Memories with Posts and{" "}
                                        <span className="highlight">Stories</span>
                                    </h2>
                                    <p className="Create" data-aos="fade-up" data-aos-delay="200" data-aos-duration="800">
                                        Create engaging content that vanishes after 24 hours.
                                    </p>
                                    <div className="info-box" data-aos="fade-up" data-aos-delay="300" data-aos-duration="800">
                                        <span>
                                            <img src={require('../images/third-section/Icon.png')} />
                                        </span>
                                        <p className="description">
                                            Engage your audience with content that captures your best
                                            moments. From timeless posts to 24-hour Stories, Zulachat
                                            offers powerful ways to connect with your followers.
                                        </p>
                                    </div>
                                    <div className="info-box" data-aos="fade-up" data-aos-delay="300" data-aos-duration="800">
                                        <span>
                                            <img src={require('../images/third-section/Icon (1).png')} />
                                        </span>
                                        <p className="description">
                                            Make Every Moment Count. Whether it's a memorable post or a
                                            fleeting Story, each moment creates an impact. Choose the
                                            format that brings your content to life and strengthens your
                                            bond with your audience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="OverlayImg">
                        <img src={require('../images/overlay-2.png')} />
                    </span>
                </section>
                <section className="MembershipArea">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="MpLeft">
                                    <h2 data-aos="fade-up" data-aos-delay="400" data-aos-duration="1200">
                                        Membership <span>Perks</span>
                                    </h2>
                                    <p data-aos="fade-up" data-aos-delay="500" data-aos-duration="800">Enjoy more features with our flexible plans</p>
                                    <ul>
                                        <li data-aos="fade-up" data-aos-delay="600" data-aos-duration="800">
                                            <span>
                                                <i className="fa-solid fa-check" />
                                            </span>
                                            <h5>Get verified and receive a golden verification badge</h5>
                                            <p>
                                                Unlock your exclusive golden verification badge, giving you
                                                more credibility and trustworthiness on the platform. Stand
                                                out from the crowd and enhance your reputation.
                                            </p>
                                        </li>
                                        <li data-aos="fade-up" data-aos-delay="500" data-aos-duration="800">
                                            <span>
                                                <i className="fa-solid fa-check" />
                                            </span>
                                            <h5>Enjoy super low fees</h5>
                                            <p>
                                                Benefit from reduced transaction fees on all your payments
                                                and exchanges. More savings mean more money in your pocket,
                                                letting you maximize your earnings with every transaction.
                                            </p>
                                        </li>
                                        <li data-aos="fade-up" data-aos-delay="600" data-aos-duration="800">
                                            <span>
                                                <i className="fa-solid fa-check" />
                                            </span>
                                            <h5>Increase your visibility on the platform</h5>
                                            <p>
                                                Boost your profile’s visibility and reach a larger audience.
                                                With our premium features, your content will be more likely
                                                to appear at the top, helping you grow your influence and
                                                attract more engagement.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="MpRight" data-aos="zoom-out" data-aos-delay="800" data-aos-duration="1200">
                                    <img src={require('../images/perk-right.png')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ChatArea">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="ChatLeft">
                                    <h2 data-aos="fade-up" data-aos-delay="200" data-aos-duration="1300">
                                        Don’t Just Chat, <img src={require('../images/white-logo.png')} />
                                    </h2>
                                    <h6 data-aos="fade-up" data-aos-delay="300" data-aos-duration="1300">Get excited!</h6>
                                    <p data-aos="fade-up" data-aos-delay="400" data-aos-duration="1300">
                                        Zulachat is launching in 2025! Soon, you’ll be able to connect,
                                        engage, and share like never before. Join a community where
                                        seamless transactions, meaningful connections, and unique
                                        features bring your social experience to a new level. Stay tuned
                                        for updates and be among the first to download Zulachat when it
                                        becomes available.
                                    </p>
                                    {/* <span>Download <img src="images/white-logo.png"></span>
                      <ul>
                          <li><a href="javascript:void(0);"><img src="images/google.png"></a></li>
                          <li><a href="javascript:void(0);"><img src="images/apple.png"></a></li>
                      </ul> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="ChatRight" data-aos="zoom-out" data-aos-delay="500" data-aos-duration="1300">
                                    <img src={require('../images/girl.png')} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="influencer-network">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <h1 data-aos="fade-up" data-aos-delay="500" data-aos-duration="1500">
                                    Apply to Join Zulachat's
                                    <br /> Influencer Network
                                </h1>
                                <p data-aos="fade-up" data-aos-delay="600" data-aos-duration="1500">Fill in your details to help us understand your influence</p>
                            </div>
                            <div className="col-lg-5 mx-auto col-md-12 col-sm-12">
                                <div className="form-container" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1500">
                                    <div className="form-group" >
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Your Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Your Email Address"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <label className="Radio ">
                                                    <input
                                                        type="radio"
                                                        name="socialAccountType"
                                                        value="Instagram"
                                                        onChange={handleChange}
                                                    />
                                                    <img
                                                        src={require('../images/sixth-section/insta.png')}
                                                        alt="Instagram"
                                                        width={24}
                                                    />
                                                    Instagram
                                                    <span className="checkmark" />
                                                </label>

                                                <label className="Radio mt-3">
                                                    <input
                                                        type="radio"
                                                        name="socialAccountType"
                                                        value="TikTok"
                                                        onChange={handleChange}
                                                    />
                                                    <img
                                                        src={require('../images/sixth-section/tiktok-new.png')}
                                                        alt="TikTok"
                                                        width={24}
                                                    />
                                                    TikTok
                                                    <span className="checkmark" />
                                                </label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="Radio twitch">
                                                    <input
                                                        type="radio"
                                                        name="socialAccountType"
                                                        value="Twitch"
                                                        onChange={handleChange}
                                                    />
                                                    <img
                                                        src={require('../images/sixth-section/twitch.png')}
                                                        alt="Twitch"
                                                        width={24}
                                                    />
                                                    Twitch
                                                    <span className="checkmark" />
                                                </label>

                                                <label className="Radio mt-3">
                                                    <input
                                                        type="radio"
                                                        name="socialAccountType"
                                                        value="Kick"
                                                        onChange={handleChange}
                                                    />
                                                    <img
                                                        src={require('../images/sixth-section/kick.png')}
                                                        alt="kick"
                                                        width={24}
                                                    />
                                                    Kick
                                                    <span className="checkmark" />
                                                </label>

                                            </div>
                                        </div>
                                        {/* <ul>
                                            <li>
                                                <label className="Radio">
                                                    <input
                                                        type="radio"
                                                        name="socialAccountType"
                                                        value="Instagram"
                                                        onChange={handleChange}
                                                    />
                                                    <img
                                                        src={require('../images/sixth-section/instagram.png')}
                                                        alt="Instagram"
                                                    />
                                                    Instagram
                                                    <span className="checkmark" />
                                                </label>
                                            </li>
                                            <li>
                                                <label className="Radio">
                                                    <input
                                                        type="radio"
                                                        name="socialAccountType"
                                                        value="TikTok"
                                                        onChange={handleChange}
                                                    />
                                                    <img
                                                        src={require('../images/sixth-section/tiktok.png')}
                                                        alt="TikTok"
                                                    />
                                                    TikTok
                                                    <span className="checkmark" />
                                                </label>
                                            </li>

                                        </ul>
                                        <ul>
                                            <li>
                                                <label className="Radio">
                                                    <input
                                                        type="radio"
                                                        name="socialAccountType"
                                                        value="Twitch"
                                                        onChange={handleChange}
                                                    />
                                                    <img
                                                        src={require('../images/sixth-section/twitch.png')}
                                                        alt="Twitch"
                                                        width={24}
                                                    />
                                                    Twitch
                                                    <span className="checkmark" />
                                                </label>
                                            </li>
                                            <li>
                                                <label className="Radio">
                                                    <input
                                                        type="radio"
                                                        name="socialAccountType"
                                                        value="Kick"
                                                        onChange={handleChange}
                                                    />
                                                    <img
                                                        src={require('../images/sixth-section/kick.png')}
                                                        alt="kick"
                                                        width={24}
                                                    />
                                                    Kick
                                                    <span className="checkmark" />
                                                </label>
                                            </li>
                                        </ul> */}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Username (on Instagram/TikTok/Twitch/Kick)"
                                            name="userName"
                                            value={formData.userName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Number of Followers (e.g. 70000)"
                                            name="numberOfFollowers"
                                            value={formData.numberOfFollowers}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group d-flex">
                                        <label className="CheckBox">
                                            <input
                                                type="checkbox"
                                                name="termsAccepted"
                                                checked={formData.termsAccepted}
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                        <p className="CheckPara">
                                            I accept all{" "}
                                            <Link to="/terms-condition">Terms &amp; Conditions</Link> and{" "}
                                            <Link to="/privacy-policy">Privacy Policy</Link>
                                        </p>
                                    </div>
                                </div>
                                <button
                                    onClick={handleSubmit}
                                    className="btn btn-danger w-100"
                                // data-bs-toggle="modal"
                                // data-bs-target="#SumbittedModal"
                                >
                                    Submit &nbsp;&nbsp;
                                    <i className="fa-solid fa-arrow-right" />
                                </button>
                            </div>
                        </div>
                    </div>
                </section >

            </div >
            <div className="ModalBox">
                <div id="FormModal" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <section className="influencer-network">
                                    <div className="container">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <h1>
                                                    Apply to Join Zulachat's
                                                    <br /> Influencer Network
                                                </h1>
                                                <p>
                                                    Fill in your details to help us understand your influence
                                                </p>
                                            </div>
                                            <div className="col-lg-5 mx-auto col-md-12 col-sm-12">
                                                <form className="form-container">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Your Name"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Your Email Address"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <ul >
                                                            <li>
                                                                <label className="Radio">
                                                                    <img src={require('../images/sixth-section/insta.png')} width={24}/>
                                                                    Instagram
                                                                    <input type="radio" />
                                                                    <span className="checkmark" />
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="Radio">
                                                                    <img src={require('../images/sixth-section/tiktok-new.png')} width={24} />
                                                                    TikTok
                                                                    <input type="radio" />
                                                                    <span className="checkmark" />
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Username (on Instagram/TikTok)"
                                                            required=""
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Number of Followers (e.g. 70000)"
                                                            required=""
                                                        />
                                                    </div>
                                                    <div className="form-group d-flex">
                                                        <label className="CheckBox">
                                                            <input type="checkbox" />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <p className="CheckPara">
                                                            I accept all{" "}
                                                            <Link to="/terms-condition">
                                                                Terms &amp; conditions
                                                            </Link>{" "}
                                                            and <Link to="/privacy-policy">Privacy Policy</Link>
                                                        </p>
                                                    </div>
                                                    <button
                                                        className="btn btn-danger w-100"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#SumbittedModal2"
                                                        data-bs-dismiss="modal"
                                                    >
                                                        Submit &nbsp;&nbsp;
                                                        <i className="fa-solid fa-arrow-right" />
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <span
                                        className="DownloadOverlayClose"
                                        href="javascript:void(0);"
                                        data-bs-dismiss="modal"
                                    >
                                        x
                                    </span>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="SumbittedModal" className="modal fade SmallModal" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="SupmitArea">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-bs-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <span>
                                        <i className="fa-solid fa-check" />
                                    </span>
                                    <h1>Thank you</h1>
                                    <p>
                                        Your details has been succeefully <br /> submitted Thanks!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="SumbittedModal2" className="modal fade SmallModal" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="SupmitArea">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-bs-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <span>
                                        <i className="fa-solid fa-check" />
                                    </span>
                                    <h1>Thank you</h1>
                                    <p>
                                        Your details has been succeefully <br /> submitted Thanks!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={emailModal}
                onHide={handleEmailModalClose}
                backdrop="static"
                keyboard={false}
                {...props}
                size="lg"
                className='ModalBox'
            >
                <a href="javascript:void(0);" class="CloseModal" data-bs-dismiss="modal" onClick={() => handleEmailModalClose()}>×</a>
                <Modal.Body>
                    <div className='influencer-network'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <h1>
                                        Apply to Join Zulachat's
                                        <br /> Influencer Network
                                    </h1>
                                    <p>Fill in your details to help us understand your influence</p>
                                </div>
                                <div className="col-lg-5 mx-auto col-md-12 col-sm-12">
                                    <div className="form-container" >
                                        <div className="form-group" >
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Your Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Your Email Address"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="Radio ">
                                                        <input
                                                            type="radio"
                                                            name="socialAccountType"
                                                            value="Instagram"
                                                            onChange={handleChange}
                                                        />
                                                        <img
                                                            src={require('../images/sixth-section/insta.png')}
                                                            alt="Instagram"
                                                            width={24}
                                                        />
                                                        Instagram
                                                        <span className="checkmark" />
                                                    </label>

                                                    <label className="Radio mt-3">
                                                        <input
                                                            type="radio"
                                                            name="socialAccountType"
                                                            value="TikTok"
                                                            onChange={handleChange}
                                                        />
                                                        <img
                                                            src={require('../images/sixth-section/tiktok-new.png')}
                                                            alt="TikTok"
                                                            width={24}
                                                        />
                                                        TikTok
                                                        <span className="checkmark" />
                                                    </label>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className="Radio twitch">
                                                        <input
                                                            type="radio"
                                                            name="socialAccountType"
                                                            value="Twitch"
                                                            onChange={handleChange}
                                                        />
                                                        <img
                                                            src={require('../images/sixth-section/twitch.png')}
                                                            alt="Twitch"
                                                            width={24}
                                                        />
                                                        Twitch
                                                        <span className="checkmark" />
                                                    </label>

                                                    <label className="Radio mt-3">
                                                        <input
                                                            type="radio"
                                                            name="socialAccountType"
                                                            value="Kick"
                                                            onChange={handleChange}
                                                        />
                                                        <img
                                                            src={require('../images/sixth-section/kick.png')}
                                                            alt="kick"
                                                            width={24}
                                                        />
                                                        Kick
                                                        <span className="checkmark" />
                                                    </label>

                                                </div>
                                            </div>
                                            {/* <ul className='d-flex'>
                                                <li>
                                                    <label className="Radio">
                                                        <input
                                                            type="radio"
                                                            name="socialAccountType"
                                                            value="Instagram"
                                                            onChange={handleChange}
                                                        />
                                                        <img
                                                            src={require('../images/sixth-section/instagram.png')}
                                                            alt="Instagram"
                                                        />
                                                        Instagram
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        <input
                                                            type="radio"
                                                            name="socialAccountType"
                                                            value="TikTok"
                                                            onChange={handleChange}
                                                        />
                                                        <img
                                                            src={require('../images/sixth-section/tiktok.png')}
                                                            alt="TikTok"
                                                        />
                                                        TikTok
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul> */}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Username (on Instagram/TikTok/Twitch/Kick)"
                                                name="userName"
                                                value={formData.userName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Number of Followers (e.g. 70000)"
                                                name="numberOfFollowers"
                                                value={formData.numberOfFollowers}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group d-flex">
                                            <label className="CheckBox">
                                                <input
                                                    type="checkbox"
                                                    name="termsAccepted"
                                                    checked={formData.termsAccepted}
                                                    onChange={handleChange}
                                                />
                                                <span className="checkmark" />
                                            </label>
                                            <p className="CheckPara">
                                                I accept all{" "}
                                                <Link to="/terms-condition">Terms &amp; Conditions</Link> and{" "}
                                                <Link to="/privacy-policy">Privacy Policy</Link>
                                            </p>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-danger w-100"
                                            onClick={handleSubmit}
                                        >
                                            Submit &nbsp;&nbsp;
                                            <i className="fa-solid fa-arrow-right" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Thank you modal Code  */}
            <Modal
                show={thankYouModal}
                onHide={handleThankYouClose}
                backdrop="static"
                keyboard={false}
                centered
                className='ModalBox SmallModal'
            >
                {/* <a href="javascript:void(0);" class="CloseModal" data-bs-dismiss="modal" onClick={() => handleEmailModalClose()}>×</a> */}
                <a href="javascript:void(0);" class="CloseModal" data-bs-dismiss="modal" onClick={() => handleThankYouClose()}>×</a>
                <Modal.Body>
                    <div className="modal-body">
                        <div className="SupmitArea">

                            <span>
                                <i className="fa-solid fa-check" />
                            </span>
                            <h1>Thank you</h1>
                            <p>
                                Your details has been succeefully <br /> submitted Thanks!
                            </p>
                            <button className='close-button' onClick={handleThankYouClose}>Close</button>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

