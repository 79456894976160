import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

export const Header = ({ onScrollToWhyZulaChat }) => {
    const navigate = useNavigate();

    const goToHomeSection = (section) => {
        navigate('/', { state: { section } });
    };
    return (
        <div>
            <header>
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <Link className="navbar-brand" to='/'>
                            <img src={require('../images/logo.png')} />
                            {/* <img src="images/logo.png" /> */}
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="fa-solid fa-bars" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        aria-current="page"
                                        onClick={() => goToHomeSection('sectionName')}
                                        
                                    >
                                        Why Zulachat
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to='/privacy-policy'>
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="mailto:info@zulachat.com" target='_blank'>
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                            <div className="StoreImages">
                                <a href="javascript:void(0);">
                                    <img src={require('../images/appstore.png')} />

                                </a>
                                <a href="javascript:void(0);">
                                    <img src={require('../images/googlestore.png')} />

                                </a>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    )
}
