import { HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { HomePage } from './Components/HomePage';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsCondition from './Components/TermsCondition';
import { Header } from './Components/Header';
import { Footer } from './Components/Footer';
import { useEffect, useRef } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css"

let basename = "";

if (process.env.NODE_ENV === "production") {
  basename = '/easy_health'
}

function App() {



  const whyZulaChatRef = useRef(null); // Create a reference to the section
    const handleScrollToWhyZulaChat = () => {
        if (whyZulaChatRef.current) {
            whyZulaChatRef.current.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the section
        }
    };

  return (
    <div className="App">
      
      <HashRouter>
        {/* <Header /> */}
        {/* <UserProvider > */}
        <Header onScrollToWhyZulaChat={handleScrollToWhyZulaChat} />
        <Routes >

          <Route path="/" element={<HomePage  whyZulaChatRef={whyZulaChatRef} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />

        </Routes>
        <Footer onScrollToWhyZulaChat={handleScrollToWhyZulaChat}/>
      </HashRouter>

    </div>
  );
}

export default App;
